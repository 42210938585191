.company_banner {
  background-color: #000;
  position: relative;
}

.company_info {
  max-width: 1320px;
  padding: 100px 15px 0;
  margin: 0 auto;
  height: 680px;
}

@media only screen and (max-width: 991px) {
  .company_info {
    height: 990px;
  }
}
