.form-label {
  margin-bottom: 0.5rem;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  text-align: left;
  display: block;
}

button.btn-send.btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  display: flex;
  align-items: center;
  background: turquoise;
  margin: 55px 0 0 0;
  padding: 10px 30px;
  border-radius: 10px;
}

button.btn-send.btn.mobile-btn {
  display: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 635px;
  min-height: 60px;
  margin-bottom: 25px;
}

.space {
  margin-top: 30px;
}

svg.iconify.iconify--akar-icons {
  margin-left: 5px;
}

.text::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 33%;
  background-image: url(../../assets/images/title-after-img.svg);
  width: 190px;
  height: 190px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-70%);
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .form-label {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 991px) {
  button.btn-send.btn.desktop-btn {
    display: none;
  }

  button.btn-send.btn.mobile-btn {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    background: turquoise;
    margin: 55px 0 0 0;
    padding: 10px 30px;
    border-radius: 10px;
  }

  .form-control {
    min-height: 50px;
  }
}

@media only screen and (max-width: 320px) {
  .form-label {
    font-size: 14px;
    line-height: 27px;
  }

  button.btn-send.btn.mobile-btn {
    padding: 7px 25px;
  }

  ul.location-details li {
    font-size: 15px;
  }
}

textarea.form-control {
  min-height: 182px;
}
ul.location-details li a {
  text-decoration: underline;
  letter-spacing: 0.5px;
}
.dropdown-menu a {
  display: block;
  text-decoration: none;
  padding: 3px 10px;
  color: #000;
  font-weight: 500;
}

textarea.form-control {
  height: 100%;
  min-height: 310px;
  resize: none;
}
