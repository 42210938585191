.main-title {
  position: relative;
  color: #ffffff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 86px;
  margin-bottom: 30px;
  z-index: 1;
}

.main-title::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  background-image: url(./assets/images/title-after-img.svg);
  width: 190px;
  height: 190px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-70%);
  z-index: -1;
}

.main-title span {
  color: #12cacb;
}

.title-text {
  text-align: center;
  color: #ffffff;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin: 0 auto;
  max-width: 1030px;
}

.title-text a {
  color: #12cacb;
  padding: 0;
  display: inline-block;
}

@media only screen and (max-width: 1199px) {
  .main-title {
    font-size: 60px;
    line-height: 78px;
  }

  .title-text {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .main-title {
    font-size: 53px;
    line-height: 70px;
  }

  .title-text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .main-title {
    font-size: 43px;
    line-height: 65px;
  }

  .main-title::after {
    content: "";
    position: absolute;
    top: -25px;
    left: 60%;
    background-image: url(http://localhost:3000/static/media/title-after-img.9e3dabf….svg);
    width: 180px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateX(-70%);
    z-index: -1;
  }
}

@media only screen and (max-width: 320px) {
  .main-title {
    font-size: 27px;
    line-height: 39px;
  }

  .title-text {
    font-size: 13px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 479px) {
  h2.main-title br {
    display: none !important;
  }
}

.navbar-nav .nav-link {
  cursor: pointer;
  text-decoration: none !important;
}

p.title-text.mb-5.text-start.mx-0 {
  margin-bottom: 1rem !important;
}

.form-control {
  min-height: 50px;
}

textarea.form-control {
  min-height: 280px;
}