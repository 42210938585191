.imprint_banner {
  position: relative;
  overflow: hidden;
  background: #000;
  padding: 270px 0 0 0;
  z-index: -1;
}
.imprint_banner img {
  height: 700px;
  opacity: 0.8;
}

ul.location-details li {
  list-style: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  text-align: start;
  margin-bottom: 17px;
}

li.location-text.d-flex {
  border-bottom: 2px solid;
  width: 100%;
}

li.location-text.d-flex p {
  margin-left: 5px;
}
.soical-wrap {
  margin: 80px 0 0 0;
  border-top: 2px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul.social-media.d-flex {
  justify-content: center;
  align-items: center;
}

ul.social-media li {
  list-style: none;
  color: #ffffff;
  font-size: 30px;
  margin: 0 15px;
}

.location-details a.nav-link {
  padding: 0;
  margin-left: 5px;
  color: #fff;
}

ul.social-media.d-flex a.nav-link {
  color: #fff;
}

ul.location-details {
  padding: 0;
  max-width: 530px;
  margin: 0 auto;
}
.social-media {
  padding: 0;
  margin: 0;
}
.main-title.heading::after {
  height: 0;
  width: 0;
}
.soical-wrap p {
  margin: 0;
}
@media only screen and (max-width: 575px) {
  ul.location-details li {
    list-style: none;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    text-align: start;
    margin-bottom: 10px;
    align-items: baseline;
  }

  .space {
    margin-top: 0;
  }

  .text::after {
    height: 0;
    width: 0;
  }
  .soical-wrap {
    display: block;
  }
}

@media only screen and (max-width: 320px) {
  ul.social-media li {
    margin-left: 0;
  }
}
