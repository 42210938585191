.navbar-nav .nav-link {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff !important;
  opacity: 0.8;
  margin-left: 15px;
}

.navbar-nav .nav-link:hover, .navbar-nav .nav-link.active {
  color: #ffa40b !important;
  transition: all 0.5s;
  text-decoration: underline;
}

.nav-item.dropdown img {
  height: 20px;
  width: 20px;
}

nav.test.navbar.navbar-expand-lg.navbar-light {
  position: fixed;
  width: 100%;
  z-index: 11;
}

.temp-class {
  background-color: #000;
  box-shadow: 0 8px 50px -2px #8080801c;
}

@media (max-width: 991px) {
  .navbar-nav .dropdown-menu a {
    display: block;
    text-decoration: none;
    padding: 6px 20px;
    color: #000;
  }

  .dropdown-menu.show {
    padding: 0;
  }

  .navbar-nav .dropdown-menu a:hover {
    background: #0001;
  }

  div#basic-navbar-nav {
    background: #000;
  }

  span.navbar-brand img {
    max-width: 145px;
  }

  .navbar-toggler {
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    z-index: 1111111;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-toggler.collapsed span {
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    transition: all 0.5s;
    left: 0;
  }

  .navbar-toggler.collapsed span:after {
    content: "";
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2px;
    background: #fff;
    left: 0;
    transition: all 0.5s;
    transform: unset;
  }

  .navbar-toggler.collapsed span:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    left: 0;
    transition: all 0.5s;
    transform: unset;
    top: 10px;
  }

  .navbar-toggler span:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 14px;
    transform: rotate(45deg);
    width: 100%;
    height: 2px;
    transition: all 0.5s;
    left: 0;
  }

  .navbar-toggler span:after {
    content: "";
    position: absolute;
    top: 14px;
    width: 100%;
    height: 2px;
    background: #fff;
    transform: rotate(-45deg);
    transition: all 0.5s;
    left: 0;
  }

  .navbar-toggler .navbar-toggler-icon {
    background: #0000;
    transition: all 0.5s;
  }

  div#basic-navbar-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 111;
    display: block;
    background-color: #000;
    padding: 80px 30px 30px;
    transform: translateX(100%);
    transition: all 0.5s;
    max-width: 370px;
    box-shadow: -7px 0px 60px #00000029;
    overflow: auto;
  }

  div#basic-navbar-nav.show {
    transform: translateX(0);
  }
}

