.home_banner {
  position: relative;
  overflow: hidden;
  background: #000;
}

.home_info {
  position: absolute;
  top: 65%;
  transform: translate(-50%, -50%);
  max-width: 1320px;
  width: 100%;
  left: 50%;
  padding: 0 15px;
}

.home_banner img {
  width: 100%;
  height: 884px;
  object-fit: cover;
  opacity: 0.5;
}
