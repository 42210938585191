.portfolio_banner {
  position: relative;
  overflow: hidden;
  background: #000;
}

.portfolio_banner img {
  height: 800px;
  opacity: 0.5;
}
